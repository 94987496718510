import './App.css';
import React from "react";
import {Menu} from "./components/Menu";
import {AboutFund} from "./components/AboutFund";
import {Gallery} from "./components/Gallery";
import {Help} from "./components/Help";
import {Contacts} from "./components/Contacts";
import {MainPage} from "./components/MainPage";
import {Articles} from "./components/Articles";
import {Dogs} from "./components/Dogs";
import {OneDog} from "./components/OneDog";
import {Cats} from "./components/Cats";
import {OneCat} from "./components/OneCat";
import {Footer} from "./components/Footer";
import {Header} from "./components/Header";
import {OnePost} from "./components/OnePost";
import {Admin} from "./admin/Admin";
import {Route,BrowserRouter} from "react-router-dom";
import {Partners} from "./components/Partners";
import {Auth} from "./admin/Auth";
import {Cabinet} from "./components/Cabinet";
import {Villi} from "./components/Villi";
import {Mushka} from "./components/Mushka";
import {Leya} from "./components/Leya";
import {Svane} from "./components/Svane";
import {Dambo} from "./components/Dambo";
import {Jimmy} from "./components/Jimmy";
import {Leo} from "./components/Leo";
import {Milan} from "./components/Milan";
import {Rassel} from "./components/Rassel";
import {Roxy} from "./components/Roxy";
import {Fibi} from "./components/Fibi";
import {Hugo} from "./components/Hugo";
import {Air} from "./components/Air";
import {Esmie} from "./components/Esmie";
import {Reports} from "./components/Reports";
import {ShortNumber} from "./components/ShortNumber";
import {AskForHelp} from "./components/AskForHelp";
import {Number7715} from "./components/Number7715";
import {Charly} from "./components/Charly";
import {RecurringPayments} from "./components/RecurringPayments";
import {Widget} from "./components/Widget";
import {Privacy} from "./components/Privacy";
import {Vocabulary} from "./components/Vocabulary";
import {Inheritance} from "./components/Inheritance";
import {MainLow} from "./components/MainLow";
import {Bongo} from "./components/Bongo";
import {Bu} from "./components/Bu";

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
    return(
      <div>
          <BrowserRouter>
              <Menu/>
              <Route exact path="/" render={()=><Header/>}/>
              <Route exact path="/" render={()=><MainPage/>}/>
              <Route path="/privacy" render={()=><Privacy/>}/>
              <Route path="/about" render={()=><AboutFund/>}/>
              <Route path="/askforhelp" render={()=><AskForHelp/>}/>
              <Route path="/gallery" render={()=><Gallery/>}/>
              <Route path="/dogs" render={()=><Dogs/>}/>
              <Route path="/OneDog/:id" render={(props)=><OneDog {...props}/>}/>
              <Route path="/cats" render={()=><Cats/>}/>
              <Route path="/OneCat/:id" render={(props)=><OneCat {...props}/>}/>
              <Route path="/help" render={()=><Help/>}/>
              <Route path="/recurringPayments" render={()=><RecurringPayments/>}/>
              <Route path="/3434" render={()=><ShortNumber/>}/>
              <Route path="/7715" render={()=><Number7715/>}/>
              <Route path="/articles" render={()=><Articles/>}/>
              <Route path="/post/:id" render={(props)=><OnePost {...props}/>}/>
              <Route path="/contacts" render={()=><Contacts/>}/>
              <Route path="/partners" render={()=><Partners/>}/>
              <Route path="/reports" render={()=><Reports/>}/>
              <Route path="/villi" render={()=><Villi/>}/>
              <Route path="/leya" render={()=><Leya/>}/>
              <Route path="/mushka" render={()=><Mushka/>}/>
              <Route path="/svane" render={()=><Svane/>}/>
              <Route path="/bongo" render={()=><Bongo/>}/>
              <Route path="/bu" render={()=><Bu/>}/>
              <Route path="/dambo" render={()=><Dambo/>}/>
              <Route path="/jimmy" render={()=><Jimmy/>}/>
              <Route path="/leo" render={()=><Leo/>}/>
              <Route path="/milan" render={()=><Milan/>}/>
              <Route path="/rassel" render={()=><Rassel/>}/>
              <Route path="/roxy" render={()=><Roxy/>}/>
              <Route path="/fibi" render={()=><Fibi/>}/>
              <Route path="/hugo" render={()=><Hugo/>}/>
              <Route path="/air" render={()=><Air/>}/>
              <Route path="/esmie" render={()=><Esmie/>}/>
              <Route path="/charly" render={()=><Charly/>}/>
              <Route path="/vocabulary" render={()=><Vocabulary/>}/>
              <Route path="/inheritance" render={()=><Inheritance/>}/>
              <Route path="/mainlow" render={()=><MainLow/>}/>
              <Route exact path="/admin/:page" render={(props)=><Admin {...props}/>}/>
              <Route path="/auth" render={()=><Auth/>}/>
              <Route path="/cabinet" render={()=><Cabinet/>}/>
              <Widget/>
              <Footer/>
          </BrowserRouter>
      </div>
    )
  }
}


export default App;
