import React from "react";
import phone from './img/phone_keyword.png';
import operators from './img/Operators_logo.PNG';
import offer_subscriber from './documents/offer_subscriber.pdf';
import offer_mts from './documents/soglashenie_easy_pay_mts.pdf';
import offer_beeline from './documents/OfferNSK_beeline.pdf';
import limits_beeline from './documents/limits-mobilnyi-platezh_beeline.pdf';
import offer_megafon from './documents/oferta_m_platezhi_megafon.pdf';
import offer_yota from './documents/oferta_m_platezh_yota.pdf';
import offer_tmobile from './documents/payment_offer_tmobile.pdf';

export class ShortNumber extends React.Component{
    render() {
        return(
            <section className="mt-100">
                <div className="container-fluid text-center d-flex">
                    <div className="row align-items-center">
                        <div className="col-lg-9 home-about-left no-padding">
                            <h5><i>При оплате с помощью короткого номера</i></h5>
                            <p>Отправьте SMS-сообщение на номер <b style={{color:"black"}}>3434</b> с ключевым словом <b style={{color:"black"}}>«НОСИКИ»</b> и через пробел укажите цифрами сумму пожертвования в рублях.<br />
                                Например: <b style={{color:"black"}}>НОСИКИ 100</b><br />
                                В ответ вы получите SMS для подтверждения платежа. Подтверждение платежа может прийти с короткого номера, на который отправляли первоначальное SMS-сообщение, или с сервисного номера оператора связи.
                            </p>
                            <img className="operators" src={operators} style={{width:'70%'}} alt="operators" />
                        </div>
                        <div className="col-lg-3 home-about-right no-padding">
                            <img className="phone" src={phone} style={{width:'250px'}} alt="phone"/>
                        </div>
                        <div className="col-lg-9 home-about-left no-padding">
                            <p>
                                Услуга доступна для абонентов МТС, билайн, Мегафон, Т-Мобайл, Yota.
                            </p>
                            <p>
                                Допустимый размер платежа — от 1 до 15 000 рублей. Стоимость отправки SMS на номер 3434 – бесплатно. Комиссия с абонента - 0%.
                            </p>
                            <p>
                                Мобильные платежи осуществляются через платёжный сервис Миксплат. Совершая платёж, вы принимаете условия <a target="_blank" rel="noreferrer" href={offer_subscriber}>Оферты</a>.
                            </p>
                            <p>
                                Информацию о порядке и периодичности оказания услуг и условиях возврата вы можете получить по телефону <a href="tel:+74957750600">+7 495 775 06 00</a> или почте <a href="mailto:support@mixplat.ru">support@mixplat.ru</a>.
                            </p>
                            <p>
                                <li><a target="_blank" rel="noreferrer" href={offer_mts}>Договор оферты МТС</a></li>
                                <li><a target="_blank" rel="noreferrer" href={offer_beeline}>Договор оферты билайн</a>, <a target="_blank" href={limits_beeline}>лимиты</a></li>
                                <li><a target="_blank" rel="noreferrer" href={offer_megafon}>Договор оферты Мегафон</a></li>
                                <li><a target="_blank" rel="noreferrer" href={offer_yota}>Договор оферты Yota</a></li>
                                <li><a target="_blank" rel="noreferrer" href={offer_tmobile}>Договор оферты Т-Мобайл</a></li>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}