import React from 'react';
import {Link} from "react-router-dom";
import GR2017 from './reports/gov_report_2017.pdf';
import GR2018 from './reports/gov_report_2018.pdf';
import GR2019 from './reports/gov_report_2019.pdf';
import GR2020 from './reports/gov_report_2020.pdf';
import GR2021 from './reports/gov_report_2021.pdf';
import GR2022 from './reports/gov_report_2022.pdf';
import GR2023 from './reports/gov_report_2023.pdf';
import YR2020 from './reports/year_report_2020.pdf';
import YR2021 from './reports/year_report_2021.pdf';
import YR2022 from './reports/year_report_2022.pdf';
import YR2023 from './reports/year_report_2023.pdf';
import FR052020 from './reports/2020_05_financial_report.pdf';
import FR062020 from './reports/2020_06_financial_report.pdf';
import FR082020 from './reports/2020_08_financial_report.pdf';
import FR092020 from './reports/2020_09_financial_report.pdf';
import FR102020 from './reports/2020_10_financial_report.pdf';
import FR112020 from './reports/2020_11_financial_report.pdf';
import FR122020 from './reports/2020_12_financial_report.pdf';
import FR012021 from './reports/2021_01_financial_report.pdf';
import FR022021 from './reports/2021_02_financial_report.pdf';
import FR032021 from './reports/2021_03_financial_report.pdf';
import FR042021 from './reports/2021_04_financial_report.pdf';
import FR052021 from './reports/2021_05_financial_report.pdf';
import FR062021 from './reports/2021_06_financial_report.pdf';
import FR072021 from './reports/2021_07_financial_report.pdf';
import FR082021 from './reports/2021_08_financial_report.pdf';
import FR092021 from './reports/2021_09_financial_report.pdf';
import FR102021 from './reports/2021_10_financial_report.pdf';
import FR112021 from './reports/2021_11_financial_report.pdf';
import FR122021 from './reports/2021_12_financial_report.pdf';
import FR012022 from './reports/2022_01_financial_report.pdf';
import FR022022 from './reports/2022_02_financial_report.pdf';
import FR032022 from './reports/2022_03_financial_report.pdf';
import FR042022 from './reports/2022_04_financial_report.pdf';
import FR052022 from './reports/2022_05_financial_report.pdf';
import FR062022 from './reports/2022_06_financial_report.pdf';
import FR072022 from './reports/2022_07_financial_report.pdf';
import FR082022 from './reports/2022_08_financial_report.pdf';
import FR092022 from './reports/2022_09_financial_report.pdf';
import FR102022 from './reports/2022_10_financial_report.pdf';
import FR112022 from './reports/2022_11_financial_report.pdf';
import FR122022 from './reports/2022_12_financial_report.pdf';

export class Reports extends React.Component{
    render(){
        return(
                <div className="container-fluid mt-100 mb-50 text-center d-flex" >
                    <div className="flex-row justify-content-center px-3 m-auto" >
                        <div className="reports mb-10 col-sm-12" >
                            <h2>Отчёты</h2>
                            <h6>2021</h6>
                            <li>Пристроили в заботливые семьи: собачек Зара, Коко, Райда, Тыковка, Льюис, Бинго, Твинки, Мерседес, Мальта и котеечек Торес, Рон, Моня, Джинкс, Бонд, Дайдай;</li>
                            <li>Помогли: кошке с онкологией, кошке Чернышке, собакам Дину и Блэку, калининградскому приюту, кошке Ките из Санкт-Петербурга;</li>
                            <li>Спасли и взяли под опеку: собачек Кук, Макки, Рассел, Льюис, Бинго, Бонго, Твинки, Блэк и котеечек Лэсси, Рон, Лея, Барсик, Черныш, Матильда, Василий;</li>
                            <li>Поддержали курс АНО "Зоркие сердца" по адаптации и социализации собак муниципальных приютов "Дубовая роща" и "Красная сосна";</li>
                            <li>Передали более 1 тонны кормов и лекарств для кошек и собак частным волонтёрам районов Москвы и Подмосковья, а также ближайших регионов.</li>
                            <h6>2020</h6>
                            <li>Февраль 2020. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <li>Август 2020. Программа стерилизации и вакцинации бездомных собак с последующем выпуском. Результат: 2 собаки;</li>
                            <li>Сентябрь 2020. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <h6>2019</h6>
                            <li>Февраль 2019. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <li>Апрель 2019. Программа стерилизации и вакцинации бездомных кошек с последующем выпуском. Результат: 7 кошек;</li>
                            <li>Сентябрь 2019. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <h6>2018</h6>
                            <li>Сентябрь 2018. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <p><b>В совокупности со дня основания фонда было собрано и передано волонтёрам-пенсионерам, частным и муниципальным приютам, а также частным опекунам: <br /> 3,5 тонны корма и более 1500 наименований лекарств</b> </p>
                        </div>
                        <div className="month-reports ml-5">
                            <h4>Ежемесячные отчеты</h4>
                            <p>
                                Если Вы не нашли свое пожертвование в отчёте, пожалуйста, свяжитесь с нами: <Link to="/contacts">Контакты</Link>
                            </p>
                        </div>
                        <div className="month-reports-2022 ml-5">
                            <h6>2022</h6>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR122022}>Финансовый отчёт за декабрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR112022}>Финансовый отчёт за ноябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR102022}>Финансовый отчёт за октябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR092022}>Финансовый отчёт за сентябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR082022}>Финансовый отчёт за август 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR072022}>Финансовый отчёт за июль 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR062022}>Финансовый отчёт за июнь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR052022}>Финансовый отчёт за май 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR042022}>Финансовый отчёт за апрель 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR032022}>Финансовый отчёт за март 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR022022}>Финансовый отчёт за февраль 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR012022}>Финансовый отчёт за январь 2022</a>
                                </ul>
                            </p>
                            </div>
                            <div className="month-reports-2021 ml-5">
                                <h6>2021</h6>
                                <p>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR122021}>Финансовый отчёт за декабрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR112021}>Финансовый отчёт за ноябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR102021}>Финансовый отчёт за октябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR092021}>Финансовый отчёт за сентябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR082021}>Финансовый отчёт за август 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR072021}>Финансовый отчёт за июль 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062021}>Финансовый отчёт за июнь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR052021}>Финансовый отчёт за май 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR042021}>Финансовый отчёт за апрель 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR032021}>Финансовый отчёт за март 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR022021}>Финансовый отчёт за февраль 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR012021}>Финансовый отчёт за январь 2021</a>
                                    </ul>
                                </p>
                            </div>
                            <div className="month-reports-2020 ml-5">
                                <h6>2020</h6>
                                <p>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR122020}>Финансовый отчёт за декабрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR112020}>Финансовый отчёт за ноябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR102020}>Финансовый отчёт за октябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR092020}>Финансовый отчёт за сентябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR082020}>Финансовый отчёт за август 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062020}>Финансовый отчёт за июль 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062020}>Финансовый отчёт за июнь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR052020}>Финансовый отчёт за май 2020</a>
                                    </ul>
                                </p>
                            </div>

                        <div className="year-reports" >
                            <h4>Годовые отчеты</h4>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2023}>Годовой отчёт за 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2022}>Годовой отчёт за 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2021}>Годовой отчёт за 2021</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2020}>Годовой отчёт за 2020</a>
                                </ul>
                            </p>
                        </div>
                        <div className="gov-reports" >
                            <h4>Отчёты в Министерство юстиции РФ</h4>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2023}>Отчёт в Минюст за 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2022}>Отчёт в Минюст за 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2021}>Отчёт в Минюст за 2021</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2020}>Отчёт в Минюст за 2020</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2019}>Отчёт в Минюст за 2019</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2018}>Отчёт в Минюст за 2018</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2017}>Отчёт в Минюст за 2017</a>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
        )
    }
}