import React from 'react';
import {Link} from "react-router-dom";
import program2021 from './reports/program_2021.pdf';
import ugrle from "./documents/ugrle.pdf";
import csr from "./documents/csr.PDF";
import tarc from "./documents/tarc.PDF";
import nfa from "./documents/nfa.pdf";
import charter from "./documents/charter.PDF";

export class AboutFund extends React.Component{
    render(){
        return(
            <section className="home-about-area mt-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 home-about-left no-padding">
                            <img src='img/common/mood3.jpeg' alt="director"/>
                            <div className="documents ml-20 mt-10">
                                <h5>Документы фонда</h5>
                                <a target="_blank" rel="noreferrer" href={ugrle}><h6 style={{color:'rgb(250,183,0)'}}><u>Свидетельство о государственной регистрации юридического лица</u></h6></a>
                                <a target="_blank" rel="noreferrer" href={csr}><h6 style={{color:'rgb(250,183,0)'}}><u>Свидетельство о государственной регистрации некоммерческой организации</u></h6></a>
                                <a target="_blank" rel="noreferrer" href={tarc}><h6 style={{color:'rgb(250,183,0)'}}><u>Свидетельство о постановке на учёт в налоговом органе</u></h6></a>
                                <a target="_blank" rel="noreferrer" href={nfa}><h6 style={{color:'rgb(250,183,0)'}}><u>Письмо-подтверждение о не включении Благотворительного фонда в Реестр некоммерческих организаций, выполняющих функции иностранного агента</u></h6></a>
                                <a target="_blank" rel="noreferrer" href={charter}><h6 style={{color:'rgb(250,183,0)'}}><u>Устав Фонда</u></h6></a>
                                <Link to="/reports"><h6 style={{color:'rgb(250,183,0)'}}><u>Отчёты</u></h6></Link>
                            </div>
                            <div className="mission ml-20 mt-10">
                                <h5>Миссия фонда</h5>
                                <p className="mb-10" >Сокращение численности бездомных животных путём: просвещения населения о вариантах помощи и ответственном обращении с животными; поддержки начинающих и малоимущих волонтёров; помощь приютам и непосредственно животным, попавшим в беду.</p>
                            </div>
                            <div className="purposes ml-20 mt-10 pb-10">
                                <h5>Цели фонда</h5>
                                    <li>Формирование имущества на основе добровольных имущественных взносов и пожертвований, направленных на обеспечение безопасности и сохранности жизни и здоровья бездомных животных, в том числе брошенных, оставшихся без крова и источников пропитания, пострадавших в результате несчастных случаев и жестокого обращения;</li>
                                    <li>Привлечение внимания общественности к проблемам жестокого обращения с животными;</li>
                                    <li>Формирование в обществе заботливого отношения к животным.</li>
                            </div>
                            <div className="programs ml-20 mb-20">
                                <h5>Программы фонда</h5>
                                <ul>
                                    <li><b>"Помогаем просто помогать"</b> - благотворительная программа на 2021 год. <a href={program2021} download="program_2021.pdf">Скачать</a></li>
                                    <li><b>"ДогДом"</b> - передержка собак на время поиска хозяев.</li>
                                    <li><b>"КэтДом"</b> - передержка кошек на время поиска хозяев.</li>
                                    <li><b>"Волонтёру все возрасты покорны"</b> - финансовая и информационная поддержка пожилых волонтёров.</li>
                                    <li><b>"Не бойся, я с тобой"</b> - финансовая и информационная поддержка начинающих и малоимущих волонтёров.</li>
                                    <li><b>"Просто помогать"</b> - просветительские мероприятия о вариантах помощи бездомным животным.</li>
                                    <li><b>"Добрый репост"</b> - информационная помощь волонтёрам.</li>
                                </ul>
                            </div>
                            <div className="askForHelp ml-20">
                                <Link to="/askforhelp" className="primary-btn text-uppercase mb-5" style={{borderRadius:"40px"}}>Попросить помощи</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 home-about-right no-padding">
                            <h2 className="mainText d-flex">
                                Благотворительный фонд помощи бездомным животным
                                "БУДУ РЯДОМ"
                            </h2>
                            <h5>Помогаем хвостатым и людям, не оставившим их в беде.</h5>
                            <p>
                                <i>Фонд "Буду рядом" существует с 2017 года и является полностью благотворительным. У фонда нет финансовой поддержки от государства. Только помощь неравнодушных людей, наших единомышленников - тех, кто помогает брошенным на произвол судьбы животным в любви и заботе дождаться домашней жизни, получить качественное медицинское обслуживание, вновь довериться человеку.</i>
                            </p>
                            <p>
                                Мы помогаем животным сами в пределах Москвы и Московской области - спасаем, лечим, стерилизуем, социализируем, пристраиваем в ответственные, любящие и заботливые семьи. И помогаем людям, которые не смогли пройти мимо чужой шерстяной беды, по всей России и в странах СНГ.
                            </p>
                            <p>
                                Поддерживаем волонтерское движение и улучшаем условия проживания животных в приютах - собираем и передаём: корма, амуницию, лекарства, наполнители и другие расходные материалы. Помогаем финансово и информационно волонтёрам пенсионного возраста, начинающим и малоимущим волонтёрам.
                            </p>
                            <p>
                                Мы создали #догдом и #кэтдом , где в комфортных условиях животные дожидаются встречи со своими людьми и переезда к ним на ПМЖ. Создаём благоприятные условия для социализации животных и подбираем им самые лучшие и ответственные семьи.
                            </p>
                            <p>
                                На сегодняшний день под личной опекой фонда находится: <Link to="/gallery">21 собака и 9 кошек</Link>. Вместе с тем, в сумме, мы поддерживаем и помогаем более 1500 животных из муниципальных и частных приютов.
                            </p>
                            <p>
                                Кроме того, активно ведём просветительскую деятельность - в Instagram-аккаунте <a href="https://www.instagram.com/charityfund_budu_ryadom/"> @charityfund_budu_ryadom </a> можно найти много интересной и полезной информации не только для волонтёров, но и для владельцев домашних животных. Мы стараемся прививать ответственное обращение с животными, поддерживать тех, кто вносит свой посильный вклад в решение такой болезненной для общества проблемы как бездомные животные, и вдохновлять окружающих делать этот мир лучше и добрее.
                            </p>
                            <p>
                                <b>Кто мы?</b> Президент фонда - Яна Баринова и несколько добровольцев, которые искренне верят, что у каждого домашнего животного должен быть этот самый настоящий дом и заботливый человек рядом. Мы обычные люди с обычными заботами, просто стараемся вносить свой посильный вклад в доброе дело. И мы всегда рады пополнению в наших рядах.
                            </p>
                            <p>
                                Всегда ваши,<br />🐱🐶БФ "Буду рядом"
                            </p>
                            <Link to="/help" className="primary-btn text-uppercase mb-5" style={{borderRadius:"40px"}}>Помочь</Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}